<template>
    <div>
        <strong>Attach to MER # {{ state.referenceID }}</strong>
        <br>
        <div class="form-group">
            <label>MER #</label>
            <input type="text" class="form-control" v-model="state.referenceID" />
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";

    export default {
        name: "TrackerMER",
        data() {
            return {
                state: fileUploadStore.state
            }
        },
        created() {
            fileUploadStore.initialize(this.$cdiVars.FILE_TYPE_TRACKER_MER_DOCUMENT);
        }

    }
</script>

<style scoped>

</style>